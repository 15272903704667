var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('SectionTextRight',{attrs:{"text":"Ste zvyknutí, že za dobrý hosting musíte veľa platiť? My nie!\n      Našim cieľom je\n  odstrániť všetky bariery na ceste pre nové start-upy a začínajúce projekty.\n  Ideme proti prúdu a ponúkame kvalitné služby rovnako začiatočníckym\n  web developerom ako aj veľkým firmám celkom zadarmo.","heading":"Novinka na trhu za lákavú cenu!","svgSource":"globe.svg"}}),_c('SectionTextMiddle',{attrs:{"bg-color-class":"bg-blue-50","heading":"Naše výhody","paragraph1":"Našim cieľom je stať sa východzou platformou pre začínajúcich web\n        vývojárov ale aj spoľahlivým partnerom, keď sa Váš projekt rozrastie. Toto sú\n        veci, ktoré sú u nás úplnou samozrejmosťou celkom zadarmo!","paragraph2":"a mnoho viac!","icons":[
      {icon: 'ban', text: 'Žiadne reklamy'},
      {icon: 'save', text: 'Denné zálohy'},
      {icon: 'clock', text: 'Prevádzka 24/7'}
    ]}}),_c('SectionTextLeft',{staticClass:"mb-52",attrs:{"text":"Zabudnite na administračné panely z roku 2000,\n       ideme s dobou! Používame vlastnú modernú administráciu,\n       ktorú neustále aktualizujeme o nové funkcie a dbáme na to,\n       aby bola ľahko používateľná, ale zároveň nebolo ubrané na jej funkčnosti.","heading":"Hostovať web u nás je hračka!","svgSource1":"hostnow.svg","svgSource2":"projects.svg"}}),_c('SectionTextMiddle',{staticClass:"mt-60",attrs:{"bg-color-class":"bg-blue-50","heading":"Naša aktuálna ponuka","paragraph1":"Každý web hostovaný u nás má tieto funkcie úplne zadarmo bez\n       akéhokoľvek poplatku! Berieme ich ako štandard a stojíme si za názorom,\n       že by nemali byť spoplatňované.","paragraph2":"a mnohé ďalšie!","icons":[
      {icon: 'shield-alt', text: 'SSL certifikát'},
      {icon: 'newspaper', text: 'Access a Error log'},
      {icon: 'database', text: 'MySQL databáza'}
    ]}}),_c('SectionTextMiddleButtons',{staticClass:"mb-10",attrs:{"heading":"Stále váhate?","text":"Pokiaľ si nie ste istý našimi sľubmi, naše služby si môžete zadarmo\n        vyskúšať. Všetkým zákazníkom ponúkame FREE plán,\n        nemusíte zadávať žiadne platobné údaje! Stačí sa zaregistrovať.","btn-left-text":"ZAREGISTROVAŤ SA","btn-left-color-class":"btn-blue","btn-right-text":"PRIHLÁSIŤ SA","btn-right-color-class":"btn-white"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }