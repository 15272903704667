<template>
  <div>
    <Header/>
    <SectionTextRight
        text="Ste zvyknutí, že za dobrý hosting musíte veľa platiť? My nie!
        Našim cieľom je
    odstrániť všetky bariery na ceste pre nové start-upy a začínajúce projekty.
    Ideme proti prúdu a ponúkame kvalitné služby rovnako začiatočníckym
    web developerom ako aj veľkým firmám celkom zadarmo."

        heading="Novinka na trhu za lákavú cenu!"

        svgSource="globe.svg"/>
    <SectionTextMiddle
        bg-color-class="bg-blue-50"

        heading="Naše výhody"

        paragraph1="Našim cieľom je stať sa východzou platformou pre začínajúcich web
          vývojárov ale aj spoľahlivým partnerom, keď sa Váš projekt rozrastie. Toto sú
          veci, ktoré sú u nás úplnou samozrejmosťou celkom zadarmo!"

        paragraph2="a mnoho viac!"

        :icons=" [
        {icon: 'ban', text: 'Žiadne reklamy'},
        {icon: 'save', text: 'Denné zálohy'},
        {icon: 'clock', text: 'Prevádzka 24/7'}
      ]"
    />
    <SectionTextLeft
        text="Zabudnite na administračné panely z roku 2000,
         ideme s dobou! Používame vlastnú modernú administráciu,
         ktorú neustále aktualizujeme o nové funkcie a dbáme na to,
         aby bola ľahko používateľná, ale zároveň nebolo ubrané na jej funkčnosti."

        heading="Hostovať web u nás je hračka!"

        svgSource1="hostnow.svg"

        svgSource2="projects.svg"

        class="mb-52"
    />
    <SectionTextMiddle

        bg-color-class="bg-blue-50"

        heading="Naša aktuálna ponuka"

        paragraph1="Každý web hostovaný u nás má tieto funkcie úplne zadarmo bez
         akéhokoľvek poplatku! Berieme ich ako štandard a stojíme si za názorom,
         že by nemali byť spoplatňované."

        paragraph2="a mnohé ďalšie!"

        :icons=" [
        {icon: 'shield-alt', text: 'SSL certifikát'},
        {icon: 'newspaper', text: 'Access a Error log'},
        {icon: 'database', text: 'MySQL databáza'}
      ]"

        class="mt-60"
    />
    <SectionTextMiddleButtons

        heading="Stále váhate?"

        text="Pokiaľ si nie ste istý našimi sľubmi, naše služby si môžete zadarmo
          vyskúšať. Všetkým zákazníkom ponúkame FREE plán,
          nemusíte zadávať žiadne platobné údaje! Stačí sa zaregistrovať."

        btn-left-text="ZAREGISTROVAŤ SA"
        btn-left-color-class="btn-blue"

        btn-right-text="PRIHLÁSIŤ SA"
        btn-right-color-class="btn-white"

        class="mb-10"

    />
  </div>
</template>

<script>
import Header from "@/components/Header";
import SectionTextRight from "@/components/SectionTextRight";
import SectionTextLeft from "@/components/SectionTextLeft";
import SectionTextMiddle from "@/components/SectionTextMiddle";
import SectionTextMiddleButtons from "@/components/SectionTextMiddleButtons";

export default {
name: "Homepage",
  components: {
    Header,
    SectionTextRight,
    SectionTextLeft,
    SectionTextMiddle,
    SectionTextMiddleButtons,
  },

  metaInfo() {
    return {title: "Domov"}
  },
}
</script>

<style scoped>

</style>